import styled from 'styled-components'

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo20.png"
import table12 from "../../../images/certification/table12.png"
import image01 from "../../../images/certification/type01-22.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
   .cost{
      .main_img{
         max-width: 1280px;
      }
   }
   @media screen and (max-width:1280px){
      .cost{
         .main_img{
            width: auto;
            height: 120px;
         }
      }
   }
`

const KsCert = () => {

   const overViewInfo = {
      title: "KS인증",
      desc: "국가표준(KS)에 따라 제품을 지속적으로 생산할 수 있는 체제임을 KS인증기관을 통해 인정을 받는 법정임의 인증제도 입니다. <br class='xl:block hidden' />산업표준화법 제15조제1항 및 같은 법 시행규칙 제9조1항에 따라 KS인증을 받은 업체는 그 제품이 한국산업표준에 적합한 것임을 나타내는 표시 ㉿를 하거나 이를 홍보할 수 있습니다.(재)한국화학융합시험연구원은 산업표준화법 제13조제1항 및 같은 법 시행규칙 제5조제4항에 따라, 산업통상자원부장관으로 부터 KS인증기관으로 지정 받은 인증기관 입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국표준협회",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "1~2개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "심사비용",
            desc: "신청비, 공장심사비, 출장비",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "KS표준과 심사기준이 제정되어 있는 규격에 대한 물품제조와 서비스 제공 업체 대상",
               ],
            },
         ],
      },
   }
   const BenefitsData = [
      { desc: "인증기업의 경쟁력 제고" },
      { desc: "공공의 안전성 확보 및 소비자 보호" },
      { desc: "물품 등의 구매 기준으로 활용" },
      { desc: "유통 및 시공 등의 단순화·투명화" },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className='cost container xl:pt-80 xl:pb-80 pt-60 pb-60'>
            <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사 비용</h3>
            <div className='main_img_wrap type_01'>
               <img className='main_img w-full xl:mt-48' src={table12} alt="" />
            </div>
         </section>
         <section className='xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <div>
                     <h4 className='leading-1em xl:text-22 font-semibold'>※ 신청 전 준비항목</h4>
                     <ul className='xl:mt-24 mt-16 pl-16'>
                        <li className='list-disc xl:text-18 text-14'>1. 인증신청 대상품목 확인</li>
                        <li className='list-disc xl:text-18 text-14 xl:mt-16 mt-8'>2. 최신 표준 및 인증심사기준 확인 </li>
                        <li className='list-disc xl:text-18 text-14 xl:mt-16 mt-8'>3. 최근 3개월 이상의 공장운영에 관한 기록 (시제품 생산기록을 포함)</li>
                     </ul>
                  </div>
                  <img className='xl:block hidden main_img xl:mt-24' src={image01} alt="심사 절차" />
                  <ul className="xl:hidden mo_items_wrap mt-16">
                     <MoItems01 bgColor="#75c5f5">신청서 작성</MoItems01>
                     <MoItems01 bgColor="#48afec">신청서 검토 및 심사계획 통보</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">공장심사 수행</MoItems01>
                     <MoItems01 bgColor="#1592dc">인증위원회 상정</MoItems01>
                     <MoItems01 bgColor="#1592dc">인증계약 체결</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container>
   )
}

export default KsCert