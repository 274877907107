import styled from "styled-components"

// Components
import { ContactBanner } from "../../components/SubPageCommonUI"

// Images
import icon01 from "../../images/common/icon/icon15.png"
import icon02 from "../../images/common/icon/icon16.png"
import icon03 from "../../images/common/icon/icon17.png"
import icon04 from "../../images/common/icon/icon18.png"
import icon05 from "../../images/common/icon/icon19.png"
import icon06 from "../../images/common/icon/icon20.png"
import icon07 from "../../images/common/icon/arrow-bottom.png"
import image01 from "../../images/customer/process.png"
import image01Mo from "../../images/customer/mobile-process.png"
import { sanitize } from "dompurify"
import { useEffect, useState } from "react"
import axios from "axios"

// Props
import { EmploymentProps } from "../../model/data"

// styles 
const Container = styled.div`
   & .overview{
      border-bottom: 1px solid var(--subColor05);
   }
   & .works{
      & .items_wrap{
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         gap: 40px 115px;
         & .icon_wrap{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: var(--subColor10);
            & .icon{
               width: 60px;
               height: auto;
            }
         }
         & .desc{
            max-width: 150px;
         }
      }
   }
   & .process{
      & .title{
         width: 180px;
         height: 100%;
      }
      & .items_wrap{
         & .item{
            border-top: 1px solid var(--subColor05);
         }
         & .item:last-of-type{
            border-bottom: 1px solid var(--subColor05);
         }
      }
      #years-select{
         -o-appearance: none;
         -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         width: 216px;
         height: 53px;
         border: 1px solid var(--subColor05);
         padding: 12px 16px;
         background-image: url(${icon07});
         background-size: 14px auto;
         background-position: center right 16px;
         color: #828282;
      }
   }
   @media screen and (max-width:1280px){
      .works {
         .items_wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .icon_wrap{
               width: 130px;
               height: 130px;
            }
         }
      }
      .process{
         #years-select{
            width: 140px;
            height: auto;
            padding: 4px 14px;
            background-position: center right 14px;
         }
      }
   }
`

export default function Recruitment() {
   const works = [
      {
         img: icon01,
         desc: "다양한 정부 및 민간 인증 관련 컨설팅",
      },
      {
         img: icon02,
         desc: "고객사와의 소통 및 인증 절차 지원",
      },
      {
         img: icon03,
         desc: "인증서류 작성 및 제출",
      },
      {
         img: icon04,
         desc: "인증 관련 최신 동향 분석 및 적용",
      },
      {
         img: icon05,
         desc: "특허분석",
      },
      {
         img: icon06,
         desc: "영업",
      },
   ]
   const processInfo = [
      {
         title: "모집부문",
         desc: "part",
      },
      {
         title: "자격요건",
         desc: "qualification",
      },
      {
         title: "우대사항",
         desc: "preferentiality",
      },
      {
         title: "근무형태",
         desc: "shift_pattern",
      },
      {
         title: "근무지",
         desc: "work_place",
      },
      {
         title: "근무시간",
         desc: "work_hours",
      },
      {
         title: "복리후생",
         desc: "welfare",
      },
      {
         title: "지원방법",
         desc: "apply",
      },
   ]

   // select
   const [selectedValue, setSelectedValue] = useState("");
   const [data, setData] = useState<EmploymentProps>();

   // 데이터 가져오기 (처음 로드될 때와 selectedValue가 변경될 때)
   useEffect(() => {
      const fetchData = async (version: string) => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/employment?version=${version}`);
            setData(response.data.data);
         } catch (error) {
            console.log(error);
         }
      };

      if (selectedValue) {
         fetchData(selectedValue);
      } else {
         // 데이터가 없을 때 기본 데이터를 불러옴
         axios.get(`${process.env.REACT_APP_API_URL}/employment`)
            .then(response => {
               setData(response.data.data);
               const initialVersion = response.data.data.versionList[0];
               setSelectedValue(initialVersion);
               fetchData(initialVersion);
            })
            .catch(error => {
               console.log(error);
            });
      }
   }, [selectedValue]);

   const handleChange = (event: any) => {
      setSelectedValue(event.target.value);
   };

   return (
      <Container>
         <div className="overview container xl:pb-80 xl:pt-0 pt-60 pb-60">
            <p className="text-center xl:leading-30 xl:text-20 text-14">
               우수조달컨설팅은 다양한 인증 컨설팅을 전문으로 하는 기업으로, 고객의 성공을 위해 최선을 다하고 있습니다.<br className="xl:block hidden" />
               성장하는 저희 회사와 함께할 유능한 인재를 모집합니다.
            </p>
         </div>
         <div className="container works leading-1em xl:text-32 text-24 font-bold xl:mt-40 pt-60">
            <h3>인증 컨설턴트 담당 업무</h3>
            <ul className="items_wrap xl:mt-48 mt-24">
               {works.map((work, index) => (
                  <li className="flex items-center xl:gap-24 gap-16" key={index}>
                     <div className="icon_wrap flex justify-center items-center">
                        <img className="icon" src={work.img} alt="" />
                     </div>
                     <div>
                        <strong className="leading-1em xl:text-16 text-14 text-pointColor01">0{index + 1}</strong>
                        <p className="desc xl:leading-26 leading-24 xl:text-18 text-14 font-normal mt-8">{work.desc}</p>
                     </div>
                  </li>
               ))}
            </ul>
         </div>
         <div className="process container xl:mt-120 mt-60">
            <h3 className="leading-1em xl:text-32 text-24 font-bold">채용 프로세스</h3>
            <img className="xl:block hidden w-full xl:mt-48 mt-24" src={image01} alt="채용 프로세스" />
            <img className="xl:hidden w-200 xl:mt-48 mt-24 ml-auto mr-auto" src={image01Mo} alt="채용 프로세스" />
            <div className="xl:mt-140 mt-60">
               <div className="flex justify-between items-end">
                  <h3 className="leading-1em xl:text-32 text-24 font-bold">채용 공고</h3>
                  <select
                     id="years-select"
                     value={selectedValue}
                     onChange={handleChange}
                  >
                     {data && data.versionList.map((versionList, index) => (
                        <option value={versionList} key={index}>{versionList}</option>
                     ))}
                  </select>
               </div>
               {data &&
                  <ul className="items_wrap xl:mt-40 mt-24">
                     {processInfo.map((info, index) => (
                        <li className="item xl:flex xl:pt-20 xl:pb-20 pt-16 pb-16" key={index}>
                           <h4 className="title xl:leading-32 xl:text-18 text-14 font-bold">{info.title}</h4>
                           {data.employments.map((part, partIndex) => {
                              const content = part[info.desc as keyof typeof part];
                              return (
                                 <p
                                    key={partIndex}
                                    className="xl:leading-32 leading-24 xl:text-18 text-14 xl:pt-0 pt-16"
                                    dangerouslySetInnerHTML={{ __html: sanitize(String(content)) }}
                                 />
                              );
                           })}
                        </li>
                     ))}
                  </ul>

               }
            </div>
         </div>
         <ContactBanner />
      </Container>
   )
}