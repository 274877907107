import { useEffect, useState } from 'react'
import axios from 'axios'

// types
import { ConsultantProps } from "../../model/company/Consultant"

// images 
import noImg01 from "../../images/common/noimg01.jpg"
import styled from 'styled-components'
import image01 from "../../images/service-info/staff01.png"
import image01Mo from "../../images/service-info/mobile-staff01.png"
import DOMPurify from 'dompurify'

// styles
const Container = styled.div`
   .items_wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px 0px;
      & .item{
         width: 50%;
         max-width: 640px;
         & .photo{
            width: 280px;
            height: 400px;
            object-fit: cover;
            border-radius: 16px;
         }
         & .text_wrap{
            .content_wrap{
               border-top: 1px solid var(--subColor05);
            }
         }
      }
   }
   @media screen and (max-width:1280px){
      .items_wrap{
         flex-wrap: nowrap;
         flex-direction: column;
         .item{
            width: 100%;
            max-width: 100%;
            .photo{
               width: 144px;
               height: auto;
            }
         }
      }
   }
`

const CertifiedCompany = () => {

   // API
   const [data, setData] = useState<ConsultantProps[]>();


   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/consultant`);
            setData(response.data.data.data);
            if (response.data.success === false) {
               alert(response.data.message);
            }
         } catch (error) {
            console.log(error);
         }
      };

      fetchData();
   }, []);

   return (
      <Container className='xl:mb-120'>
         <div>
            <h2 className='text-center xl:text-40 text-24 font-bold xl:mt-80 mt-40 xl:mb-80 mb-40'>컨설턴트 소개</h2>
            <ul className='items_wrap container'>
               {
                  data ? (
                     data.map((data, index) => (
                        <li className='item flex items-center xl:gap-32 gap-16' key={index}>
                           <img
                              src={data.image ? (data.image) : (noImg01)}
                              className='photo'
                              alt="대표 이미지"
                           />
                           <div className='text_wrap'>
                              <div>
                                 <div className='profile_wrap xl:mb-24 mb-16'>
                                    <p className='xl:text-16 text-12 font-bold text-pointColor01'>{data.rank}</p>
                                    <strong className='leading-1em xl:text-32 text-16 font-bold pt-8'>{data.name}</strong>
                                    {data.department &&
                                       <p
                                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.department) }}
                                          className='xl:leading-28 leading-26 xl:text-16 text-14 xl:mt-24 mt-16'>
                                       </p>}
                                 </div>
                                 {data.content ? (
                                    <ul className='content_wrap xl:pt-24 pt-16 xl:mt-24 mt-16'>
                                       {data.content && data.content.includes('<br/>') ? (
                                          data.content.split('<br/>').map((content, index) => (
                                             <li className='list-disc xl:text-16 text-12 ml-12' key={index}>{content}</li>
                                          ))
                                       ) : (
                                          <li className='list-disc xl:text-16 text-12 ml-12'>{data.content}</li>
                                       )}
                                    </ul>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           </div>
                        </li>
                     ))
                  ) : (
                     <li>게시글이 없습니다.</li>
                  )
               }
            </ul>
         </div>
         <div className='container xl:pt-120 pt-60'>
            <h2 className='leading-1em text-center xl:text-40 text-24 font-bold xl:mb-80 mb-24'>원스톱 서비스 구성원</h2>
            <img className='xl:block hidden w-full' src={image01} alt="원스톱 서비스 구성원" />
            <img className='xl:hidden w-full' src={image01Mo} alt="원스톱 서비스 구성원" />
         </div>
      </Container>
   )
}

export default CertifiedCompany