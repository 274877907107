// Components
import { ContactBanner } from "../../components/SubPageCommonUI"

// Images
import image01 from "../../images/service-info/staff01.png"
import image01Mo from "../../images/service-info/mobile-staff01.png"

export default function ServiceStaff() {
   return (
      <div>
         <section className="container">
            <h3 className="text-center leading-1em xl:text-40 text-24 font-bold xl:mt-80 mt-60">주요 구성원</h3>
            <img className="xl:block hidden w-full mt-48" src={image01} alt="주요 구성원" />
            <img className="xl:hidden w-full mt-48" src={image01Mo} alt="주요 구성원" />
         </section>
         <ContactBanner />
      </div>
   )
}