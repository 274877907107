import styled from 'styled-components'

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Distinction, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo14.png"
import image01 from "../../../images/certification/type01-16.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .examination{
      & .main_img{
         max-width: 1020px;
      }
   }
`

const NepCert = () => {

   const overViewInfo = {
      title: "신제품인증(NEP)",
      desc: "국내에서 최초로 개발된 기술 도는 이에 따르는 대체 기술을 적용한 제품을 정부가 인증하고, <br class='xl:block hidden'/>제품의 초기 판로 지원과 기술 개발을 촉진하기 위한 제도입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국산업기술진흥협회",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "인증일로부터 3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "6개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "정기 심사 연 3회",
            bgImg: icon13,
         },
         {
            title: "심사비용",
            desc: "서류·면접 심사: 20만원 현장 심사: 50만원",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "신청제품의 핵심기술이 국내에서 최초로 개발된 기술 또는 이에 준하는 대체기술로서 기존의 기술을 혁신적으로 개선ㆍ개량한 신기술일 것",
                  "신청제품의 성능과 품질이 같은 종류의 다른 제품과 비교하여 뛰어나게 우수할 것",
                  "같은 품질의 제품이 지속적으로 생산될 수 있는 품질경영체제를 구축ㆍ운영하고 있을 것",
                  "타인의 지식재산권을 침해하지 아니할 것",
                  "기술적 파급 효과가 클 것",
                  "수출 증대 및 관련 산업에 미치는 영향 등 경제적 파급 효과가 클 것",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "이미 국내에서 일반화된 기술을 적용한 제품",
                  "제품을 구성하는 핵심 부품 일체가 수입품인 제품",
                  "적용한 신기술이 신제품의 고유 기능과 목적구현하는 데 필요하지 아니한 제품",
                  "엔지니어링기술이 주된 기술이 되는 시설",
                  `식품, 의약품 및 「의료기기법」 제2조제1항에 따른 의료기기 중 인체에 미치는 잠재적 위해성이 상대적으로 높은 것으로서 산업통상자원부령으로 정하는 의료기기*<br/>
                  <span class='block xl:pt-12 text-subColor03'>* 「의료기기법 시행규칙」 제2조 및 별표 1에 따른 3등급 및 4등급 의료기기</span>`,
                  "누구나 쉽고 간단하게 모방할 수 있는 아이디어 제품",
                  "과학적으로 입증되지 아니한 이론을 적용한 제품",
                  "그 밖에 선량한 풍속에 반하거나 공공의 질서를 해할 우려가 있는 제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      { desc: "공공기관 20% 의무구매(산업통상자원부)" },
      { desc: "산업기술혁신촉진법에 따라 산업기반자금 융자사업자 선정시 우대" },
      { desc: "기술우대보증제도 지원대상(기술심사 면제)" },
      { desc: "중소기업기술혁신개발사업에 가점(중소벤처기업부)" },
      { desc: "자본재공제조합의 입찰보증, 계약보증, 차약보증, 지급보증, 하자보증 우대 지원" },
      { desc: "공공기관 우선구매 대상(중소벤처기업부)" },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <Distinction />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80 bg-subColor11">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className='xl:block hidden'>
                  <img className="main_img w-full" src={image01} alt="심사절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <div>
                     <MoItems01 bgColor="#75c5f5">서류,면접 심사</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ심사장소/시간 지정통보</p>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ기관 제품설명자 참석면접</p>
                  </div>
                  <div className='mt-8'>
                     <MoItems01 bgColor="#48afec">현장심사</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ서류,면접심사 결과에 따라 현장심사대상 기관에 개별통보</p>
                  </div>
                  <div className='mt-8'>
                     <MoItems01 bgColor="#2ea4e9">사전예고</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ국가기술표준원 및 신제품 홈페이지 공고</p>
                  </div>
                  <div className='mt-8'>
                     <MoItems01 bgColor="#1592dc">이의조정 심사</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ심사장소/시간 지정통보</p>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ제품 담당자/이해관계인 참석면접</p>
                  </div>
                  <div className='mt-8'>
                     <MoItems01 bgColor="#1592dc">종합심사</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ심사장소/시간 지정통보</p>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ기관 제품 설명자 참석면접(필요시)</p>
                  </div>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container>
   )
}

export default NepCert