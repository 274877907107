import { Link } from "react-router-dom"
import axios from "axios"
import CountUp from 'react-countup';
import { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from 'swiper/modules';
import styled, { keyframes } from "styled-components"
import DOMPurify, { sanitize } from "dompurify"
import { useInView } from "react-intersection-observer";

// components
import PrivacyAgreement from "../components/PrivacyAgreement";
import FooterNoMg from "../components/FooterNoMg";

// type
import { FormDataProps } from "../model/formdata";
import { MainDataProps } from "../model/data";

// images
import about01 from "../images/home/about_01.webp"
import strength from "../images/home/strength.webp"
import performance01 from "../images/home/icon_performance01.png"
import performance02 from "../images/home/icon_performance02.png"
import performance03 from "../images/home/icon_performance03.png"
import performance04 from "../images/home/icon_performance04.png"
import enterprise01 from "../images/home/logo/logo15.png"
import enterprise02 from "../images/home/logo/logo16.png"
import enterprise03 from "../images/home/logo/logo17.png"
import enterprise04 from "../images/home/logo/logo18.png"
import enterprise05 from "../images/home/logo/logo19.png"
import enterprise06 from "../images/home/logo/logo20.png"
import enterprise07 from "../images/home/logo/logo21.png"
import enterprise08 from "../images/home/logo/logo22.png"
import enterprise09 from "../images/home/logo/logo23.png"
import enterprise10 from "../images/home/logo/logo24.png"
import enterprise11 from "../images/home/logo/logo25.png"
import enterprise12 from "../images/home/logo/logo26.png"
import enterprise13 from "../images/home/logo/logo27.png"
import enterprise14 from "../images/home/logo/logo28.png"
import enterprise15 from "../images/home/logo/logo29.png"
import enterprise16 from "../images/home/logo/logo30.png"
import enterprise17 from "../images/home/logo/logo31.png"
import enterprise18 from "../images/home/logo/logo32.png"
import enterprise19 from "../images/home/logo/logo33.png"
import enterprise20 from "../images/home/logo/logo34.png"
// import certification01 from "../images/home/certification01.webp"
// import certification02 from "../images/home/certification02.webp"
// import certification03 from "../images/home/certification03.webp"
// import certification04 from "../images/home/certification04.webp"
import competitivness01 from "../images/home/icon_competitivness01.webp"
import competitivness02 from "../images/home/icon_competitivness02.webp"
import competitivness03 from "../images/home/icon_competitivness03.webp"
import competitivness04 from "../images/home/icon_competitivness04.webp"
import competitivness05 from "../images/home/icon_competitivness05.webp"
import competitivness06 from "../images/home/icon_competitivness06.webp"
import corporategrade01 from "../images/home/corporategrade01.png"
import corporategrade02 from "../images/home/corporategrade02.png"
import notice01 from "../images/home/icon_notice01.webp"
import notice02 from "../images/home/icon_notice02.webp"
import notice03 from "../images/home/icon_notice03.webp"
import contactLogo01 from "../images/home/logo-bottom/logo01.webp"
import contactLogo02 from "../images/home/logo-bottom/logo02.webp"
import contactLogo03 from "../images/home/logo-bottom/logo03.webp"
import contactLogo04 from "../images/home/logo-bottom/logo04.webp"
import contactLogo05 from "../images/home/logo-bottom/logo05.webp"
import contactLogo06 from "../images/home/logo-bottom/logo06.webp"
import contactLogo07 from "../images/home/logo-bottom/logo07.webp"
import contactLogo08 from "../images/home/logo-bottom/logo08.webp"
import contactLogo09 from "../images/home/logo-bottom/logo09.webp"
import contactLogo10 from "../images/home/logo-bottom/logo10.webp"
import contactLogo11 from "../images/home/logo-bottom/logo11.webp"
import contactLogo12 from "../images/home/logo-bottom/logo12.webp"
import contactLogo13 from "../images/home/logo-bottom/logo13.webp"
import contactLogo14 from "../images/home/logo-bottom/logo14.webp"
import contactLogo15 from "../images/home/logo-bottom/logo15.webp"
import contactLogo16 from "../images/home/logo-bottom/logo16.webp"
import contactLogo17 from "../images/home/logo-bottom/logo17.webp"
import contactLogo18 from "../images/home/logo-bottom/logo18.webp"
import contactLogo19 from "../images/home/logo-bottom/logo19.webp"
import contactLogo20 from "../images/home/logo-bottom/logo20.webp"
import contactLogo21 from "../images/home/logo-bottom/logo21.webp"
import contactLogo22 from "../images/home/logo-bottom/logo22.webp"
import contactLogo23 from "../images/home/logo-bottom/logo23.webp"
import contactLogo24 from "../images/home/logo-bottom/logo24.webp"
import contactLogo25 from "../images/home/logo-bottom/logo25.webp"

// css
import 'swiper/css';
import 'swiper/css/pagination';
import Popup from "../components/Popup";

const MainWrap = styled.div`
   .main_swiper .swiper-pagination{
      bottom: 0px;
   }
   .main_swiper .swiper-pagination .swiper-pagination-bullet{
      opacity: 1;
      width: 8px;
      height: 8px;
      margin: 0px 6px;
      background-color: var(--subColor05);
   }
   .main_swiper .swiper-pagination .swiper-pagination-bullet-active{
      background-color: var(--pointColor02);
   }
   .announcement_swiper {
      .slide {
         .btn_a{
            overflow: hidden;
            .title{
               height: 60px;
            }
            .announcement_contents,
            .announcement_contents *{
               font-size: 18px !important;
               font-weight: 400 !important;
               color: var(--subColor03) !important;
            }
            .announcement_contents{
               max-height: 105px;
            }
         }
      }
   }
   .swiper-slide.banner_ani{
      @keyframes mainText {
         0%{
            width: 0px;
         }
         70%{
            opacity: 0;
            width: 50%;
         }
         100%{
            opacity: 1;
            width: 100%;
         }
      }
      @keyframes leftText {
         0%{
            color: transparent;
         }
         100%{
            color: #fff;
         }
      }
      @keyframes rightText {
         0%{
            -webkit-text-stroke: 2px #fff;
            color: transparent;
         }
         100%{
            -webkit-text-stroke: 2px var(--pointColor01);
            color: var(--pointColor01);
         }
      }
      @keyframes sideTextLine {
         0%{
            max-width: 0px;
         }
         100%{
            max-width: 100%;
         }
      }
      .main_text{
         overflow: hidden;
         animation: mainText 0.7s 0.9s ease-out forwards;
         opacity: 0;
         display: inline-block;
         white-space: nowrap;
         width: 0px;
      }
      .text{
         display: flex;
         align-items: center;
         gap: 24px;
         font-size: 108px;
         font-weight: 700;
         color: transparent;
      }
      .text_left{
         animation: leftText 0.3s 1s ease-out forwards;
         -webkit-text-stroke: 2px #fff;
         &::after{
            animation: sideTextLine 0.1s 1s ease-out forwards;
            content: '';
            display: inline-block;
            clear: both;
            width: 68px;
            max-width: 0px;
            height: 1px;
            background-color: #fff;
            }
      }
      .text_right{
         animation: rightText 0.3s 1s ease-out forwards;
         -webkit-text-stroke: 2px #fff;
         &::before{
            animation: sideTextLine 0.1s 1s ease-out forwards;
            content: '';
            display: inline-block;
            clear: both;
            width: 68px;
            max-width: 0px;
            height: 1px;
            background-color: #fff;
            }
      }
      @media screen and (max-width:1280px){
         @keyframes mainText {
            0%{
               opacity: 0;
               max-height: 0px;
            }
            70%{
               opacity: 0;
            }
            100%{
               opacity: 1;
               max-height: 100%;
            }
         }
         @keyframes sideTextLine {
            0%{
               width: 1px;
               height: 0px;
            }
            100%{
               width: 1px;
               height: 80px;
            }
         }
         .mb_text_wrap{
            flex-wrap: wrap;
         }
         flex-direction: column;
         .text{
            flex-direction: column;
            gap: 20px;
            line-height: 1em;
            font-size: 74px;
         }
         .main_text{
            width: 100% !important;
            max-height: 0px;
         }
         .text_left{
            &::after{
               width: 1px;
               max-width: 1px;
               height: 0px;
               margin-bottom: 24px;
            }
         }
         .text_right{
            &::before{
               width: 1px;
               max-width: 1px;
               height: 0px;
               margin-top: 24px;
            }
         }
      }
   }
   .mb_swiper .text{
      display: none;
   }
`
const LoopBanner = keyframes`
   0%{
      transform: translateX(0%);
   }
   100%{
      transform: translateX(-100%);
   }
`
const MaxWidth = keyframes`
   0%{
      max-width: 0px;
   }
   100%{
      max-width: auto;
   }
`
const FadeIn = keyframes`
   0%{
      opacity: 0;
   }
   100%{
      opacity: 1;
   }
`
const MainBanner = styled.section`
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 980px;
   max-height: 100vh;
   background-color: #fff;
   .mb_swiper {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      .mb_bg{
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   &>h2{
      color: #fff;
   }
   @media screen and (max-width:1280px){
      height: 100vh;
   }
`
const MbTextWrap = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: flex;
   justify-content: center;
   align-items: center;
`
const NoticeArea = styled.div`
   display: flex;
   position: absolute;
   bottom: 0px;
   left: 50%;
   transform: translateX(-50%);
`
const NoticeBox = styled.div<BgColor>`
   display: flex;
   align-items: center;
   width: 50%;
   height: 100px;
   padding-left: 40px;
   background-color: ${props => props.bgcolor};
   & *{
      color: #fff;
   }
   @media screen and (max-width:1280px){
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 16px;
      .text{
         height: fit-content;
      }
   }
`
const AboutWrap = styled.section`
   display: flex;
   justify-content: space-between;
   align-items: center;
   & .desc_text{
      color: var(--subColor03);
   }
   & .link_wrap{
      &>a{
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 270px;
         border-bottom: 1px solid var(--subColor03);
      }
      &>a:hover{
         border-color: var(--subColor09);
      }
      &>a:hover *{
         color: var(--subColor09);
      }
      &>a:hover .icon{
         transform: rotate(45deg);
      }
   }
   & .img_about{
      width: 60%;
      max-width: 740px;
      margin-right: -210px;
      @media screen and (max-width:1800px){
         margin-right: 0px;
      }
      @media screen and (max-width:1500px){
         width: 45%;
         margin-right: 0px;
      }
   }
   @media screen and (max-width:1280px){
      display: block;
      .link_wrap{
         display: flex;
         justify-content: center;
         gap: 12px;
      }
      .img_about{
         width: 100%;
         margin-left: auto;
         margin-right: auto;
      }
   }
   @media screen and (max-width:767px){
      .link_wrap{
         display: block;
         width: 100%;
         a{
            width: 100%;
         }
         a:not(:last-of-type){
            margin-bottom: 16px;
         }
      }
   }
`
const SubTitle = styled.p`
   letter-spacing: 3.6px;
   color: var(--pointColor01);
   @media screen and (max-width:1280px){
      text-align: center;
   }
`
const Performance = styled.section`
   background-color: #fbfbff;
   & .box_point{
      border-radius: 16px;
      box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.16);
      color: var(--subColor09);
      background-color: #fff;
   }
   & .performance_items{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      & .icon_wrap{
         display: flex;
         justify-content: center;
         align-items: center;
         width: 150px;
         height: 150px;
         box-shadow: 3px 3px 10px 0 #e4e4e4;
         border-radius: 32px;
         background-color: #fff;
      }
      & .icon{
         width: 100%;
         max-width: 80px;
      }
   }
   
   & .counter_wrap,
   & .counter_wrap *{
      color: var(--pointColor02);
   }
   & .enterprise_wrap {
      overflow: hidden;
      width: 100%;
      & .enterprise_ul_wrap{
         display: flex;
         width: fit-content;
      }
      & .enterprise_ul_wrap .enterprise_ul{
         animation: ${LoopBanner} 60s 0s linear infinite;
         display: flex;
         gap: 40px;
         width: fit-content;
         box-sizing: content-box;
         padding-right: 40px;
         & .logo{
            width: auto;
            height: 50px;
         }
      }
   }
   @media screen and (max-width:1280px){
      .box_point{
         padding: 14px 24px;
      }
      .performance_items{
         flex-wrap: wrap;
         justify-content: center;
         gap: 16px;
         li{
            width: 50%;
            max-width: 163px;
            .icon_wrap{
               width: 100%;
               height: auto;
               aspect-ratio: auto 1 / 1;
            }
         }
      }
      .enterprise_wrap{
         .enterprise_ul_wrap {
            .enterprise_ul{
               gap: 16px;
               .logo{
                  height: 40px;
               }
            } 
         }
      }
   }
`
const Certification = styled.section`
   .items_wrap{
      .item{
         *{
            text-align: center;
         }
         &:last-of-type>.title_wrap::after{
            content: none;
         }
         .title_wrap{
            position: relative;
            height: auto;
            min-height: 93px;
            border-radius: 9999px;
            background-color: #fff;
            &::after{
               content: '';
               display: block;
               clear: both;
               z-index: -9;
               position: absolute;
               top: 50%;
               right: 0px;
               transform: translateX(100%);
               width: 100%;
               height: 3px;
               border-top: 2px dashed #79926b;
            }
         }
      }
   }
   @media screen and (max-width:1280px){
      .items_wrap{
         overflow: hidden;
         .item{
            &:nth-of-type(2n)>.title_wrap::after{
               content: none;
            }
            .title_wrap{
               min-height: 76px;
            }
         }
      }
   }
`
const Competitivness = styled.section`
   background-color: var(--subColor10);
   & .container{
      display: flex;
      justify-content: space-between;
      background-color: var(--subColor10);
   }
   & .competitivness_item_wrap{
      overflow-y: scroll;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px 32px;
      max-width: 672px;
      max-height: 480px;
      box-sizing: content-box;
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
      & .item{
         height: 380px;
         box-shadow: 3px 3px 10px 0 #e4e4e4;
         border-radius: 30px;
         padding: 40px 32px;
         background-color: #fff;
         background-size: auto 80px;
         background-position: bottom 24px right 32px;
         & .count{
            width: fit-content;
            line-height: 1em;
            color: var(--pointColor01);
         }
         & .count::after{
            content: '';
            clear: both;
            display: block;
            height: 3px;
            margin-top: 6px;
            background-color: var(--pointColor02);
         }
      }
      & .item:nth-of-type(2n-1){
         margin-top: -60px;
      }
      & .item:nth-of-type(1){
         background-image: url(${competitivness01});
      }
      & .item:nth-of-type(2){
         background-image: url(${competitivness02});
      }
      & .item:nth-of-type(3){
         background-image: url(${competitivness03});
      }
      & .item:nth-of-type(4){
         background-image: url(${competitivness04});
      }
      & .item:nth-of-type(5){
         background-image: url(${competitivness05});
      }
      & .item:nth-of-type(6){
         background-image: url(${competitivness06});
      }
   }
   & .competitivness_item_wrap::-webkit-scrollbar {
      display: none;
   }
   @media screen and (max-width: 1280px){
      .container{
         display: block;
         width: calc(100% - 32px);
         margin-left: auto;
         margin-right: auto;
      }
      .scroll_wrap{
         overflow-x: scroll;
         &::-webkit-scrollbar{
            width: 10px;
            height: 3px;
         }
         &::-webkit-scrollbar-track{
            background-color: var(--subColor05);
         }
         &::-webkit-scrollbar-thumb{
            background-color: var(--pointColor03);
         }
      }
      .competitivness_item_wrap{
         display: flex;
         flex-wrap: wrap;
         width: max-content;
         max-width: none;
         gap: 0px 16px;
         .item{
            width: 320px;
         }
         .item:nth-of-type(2n-1){
            margin-top: 0px;
         }
      }
   }
`
const CorporateGrade = styled.section`
   background-color: var(--subColor10);
   & .item_wrap{
      & .item{
         width: 50%;
         & .main_img{
            width: 100%;
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
         }
         & .title{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--subColor03);
            border-radius: 9999px;
            width: 214px;
            height: 58px;
            margin-left: auto;
            margin-right: auto;
         }
      }
   }
   @media screen and (max-width:1280px){
      .item_wrap .item{
         width: 100%;
      }
   }
`
const Strength = styled.section`
   background-color: var(--subColor10);
   & .item_graph{
      opacity: 0;
      & .graph_wrap{
         display: flex;
         justify-content: flex-end;
         align-items: center;
         gap: 16px;
         width: fit-content;
         margin-left: auto;
         & .graph{
            width: 1024px;
            /* max-width: 500px; */
            height: 45px;
            border-radius: 9999px;
            margin-left: auto;
         }
      }
   }
   & .item_graph:nth-of-type(1).on{
      animation: ${FadeIn} 1s 0s ease-out forwards;
   }
   & .item_graph:nth-of-type(2).on{
      animation: ${FadeIn} 1s 0.3s ease-out forwards;
   }
   & .item_graph:nth-of-type(3).on{
      animation: ${FadeIn} 1s 0.7s ease-out forwards;
   }
   & .item_graph:nth-of-type(1).on .graph_wrap .graph{
      animation: ${MaxWidth} 1s 0s ease-out forwards;
      max-width: 1024px;
      background-color: var(--pointColor02);
   }
   & .item_graph:nth-of-type(2).on .graph_wrap .graph{
      animation: ${MaxWidth} 1s 0.3s ease-out forwards;
      max-width: 840px;
      background-color: var(--subColor09);
   }
   & .item_graph:nth-of-type(3).on .graph_wrap .graph{
      animation: ${MaxWidth} 1s 0.7s ease-out forwards;
      max-width: 455px;
      background-color: var(--subColor04);
   }
   & .desc{
      color: var(--subColor04);
   }
   & .content02 .item_wrap{
      & .item{
         width: 600px;
         & .text{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90px;
            border-radius: 9999px;
         }
         & .text_before{
            border: 1px solid var(--subColor03);
            border-radius: 9999px;
         }
         & .text_after{
            color: #fff;
         }
      }
   }
   @media screen and (max-width: 1280px){
      .item_graph .graph_wrap{
         width: 100%;
         .graph {
            height: 30px;
            margin-left: 0px;
         }
      }
      .item_graph:nth-of-type(1).on .graph_wrap .graph{
         width: 75%;
      }
      .item_graph:nth-of-type(2).on .graph_wrap .graph{
         width: 65%;
      }
      .item_graph:nth-of-type(3).on .graph_wrap .graph{
         width: 50%;
      }
      .content02 .item_wrap .item{
         width: 100%;
         .text{
            height: auto;
            min-height: 70px;
         }
         .other{
            border-bottom: 1px solid var(--subColor04);
         }
      }
   }
`
const Youtube = styled.section`
   & .swiper{
      & .slide{
         & .btn_a .thumbnail{
            width: 100%;
            height: auto;
            border-radius: 16px;
         }
      }
   }
`
const Notice = styled.section`
   & .announcement_swiper{
      overflow: hidden;
      height: 290px;
      box-sizing: content-box;
      & .slide{
         & .btn_a{
            display: flex;
            flex-direction: column;
            height: 100%;
            border-top: 1px solid var(--subColor03);
            border-bottom: 1px solid var(--subColor08);
         }
      }
   }
   & .content02{
      & .item_wrap{
         & .item{
            border-radius: 16px;
         }
         & .item:nth-of-type(1){
            border: 2px solid var(--subColor09);
         }
         & .item:nth-of-type(2){
            border: 2px solid var(--pointColor01);
         }
         & .item:nth-of-type(3){
            border: 2px solid var(--pointColor02);
         }
         & .icon_main{
            width: auto;
            height: 70px;
         }
         & .icon_arrow_wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin-left: auto;
         }
      }
   }
   @media screen and (max-width:1280px){
      .announcement_swiper{
         height: 240px;
      }
      .content02 .item_wrap .icon_main{
         height: 53px;
      }
      .content02 .item_wrap .icon_arrow_wrap{
         width: 38px;
         height: 38px;
      }
   }
`
const ContactUs = styled.section`
   
`
const LogoBanner = styled.section`
   overflow: hidden;
   display: flex;
   align-items: center;
   height: 80px;
   box-shadow: 0 -5px 6px 0 rgba(0, 0, 0, 0.08);
   & .logo_wrap{
      animation: ${LoopBanner} 50s linear 0s infinite forwards;
      & img{
         width: auto;
         height: 40px;
      }
   }
   @media screen and (max-width:1280px){
      height: 60px;
   }
`

// props
interface BgColor {
   bgcolor: any;
}

const Home: React.FC = () => {
   const isMobile = document.documentElement.clientWidth < 1280

   useEffect(() => {
      document.getElementById("header")?.classList.remove("on")

      return () => {
         document.getElementById("header")?.classList.add("on")
      }
   }, [])

   FooterNoMg();

   const [privacyShow, setPrivacyShow] = useState(false);
   const openPrivacyAgreement = () => {
      setPrivacyShow(true)
   }
   const closePrivacy = () => {
      setPrivacyShow(false)
   }

   const performanceItems = [
      { img: performance02, title: "우수조달인증", count: 2900 },
      { img: performance01, title: "성능인증", count: 1600 },
      { img: performance03, title: "다수공급자계약", count: 5525 },
      { img: performance04, title: "벤처, 혁신, 이노비즈 등", count: 607 }
   ]
   const enterpriseItems = [
      enterprise01,
      enterprise02,
      enterprise03,
      enterprise04,
      enterprise05,
      enterprise06,
      enterprise07,
      enterprise08,
      enterprise09,
      enterprise10,
      enterprise11,
      enterprise12,
      enterprise13,
      enterprise14,
      enterprise15,
      enterprise16,
      enterprise17,
      enterprise18,
      enterprise19,
      enterprise20,
   ]
   const CompetitivnessItems = [
      {
         count: "01",
         title: "&#60;SINCE 2002&#62; <br/> 23년의 컨설팅 경력",
         desc: `
                  2002년도 창업 이래 
                  조달우수제품인증과 성능인증을 
                  주력으로 컨설팅한 
                  조달전문기업 입니다.
               `
      },
      {
         count: "02",
         title: "국내 최초 <br/> 조달청 컨설팅 시작",
         desc: `
                  우수조달컨설팅의 성장을 
                  국가기관이 인정하여 
                  각종 인증 컨설팅 용약 계약을 
                  체결하게 되었습니다.
               `
      },
      {
         count: "03",
         title: "인증 성공률 <br/> 95% 보장",
         desc: `
                  우수조달인증 조달청 
                  통계 성공률 15%~25% 
                  우수조달컨설팅 본사 
                  인증 성공률 95%
               `
      },
      {
         count: "04",
         title: "총 8천 개 사의 <br/> 성공실적",
         desc: `
                  수만 개 업체 발표 예행연습 경험을 
                  토대로 우수조탈컨설팅만의 
                  성공합격 공식이 
                  축적되어 있습니다.
               `
      },
      {
         count: "05",
         title: "매출 90% 이상 <br/> 우수조달인증이 비중",
         desc: `
                  타 인증과는 달리
                  우수조달 인증은
                  매출과 직결 되기에
                  경쟁률이 매우 높습니다. 
               `
      },
      {
         count: "06",
         title: '국내 유일한 <br/> "A0" 신용등급',
         desc: `
                  A0의 기업평가등급으로
                  신용능력이 최우량급이며 
                  환경변화와 현금흐름창출능력이 
                  최상급인 상위 3% 기업입니다.
               `
      }
   ]
   const CertificationInfo = [
      {
         path: '/certification/procurement',
         title: "조달인증",
         desc: `
            우수조달제품인증<br/>
            혁신제품인증<br/>
            다수공급자계약(mas)<br/>
            품질보증조달물품인증<br/>
            벤처나라 등록<br/>
            우수조달공동상표인증<br/>
            디지털서비스인증<br/>
            소프트웨어 3자단가계약<br/>
            G-PASS
         `,
         bgColor: "#75c5f5",
      },
      {
         path: '/certification/quality-cert',
         title: "품질인증",
         desc: `
            성능인증<br/>
            K마크<br/>
            환경표지인증<br/>
            KC인증<br/>
            GR인증<br/>
            GS인증<br/>
            단체표준인증<br/>
            고효율에너지<br/>
            S마크인증<br/>
            Q마크인증<br/>
            ISO9001<br/>
            ISO14001
         `,
         bgColor: "#2ea4e9",
      },
      {
         path: '/certification/tech-cert',
         title: "기술 인증",
         desc: `
            NEP<br/>
            NET<br/>
            KS인증<br/>
            녹색기술인증
         `,
         bgColor: "#1592dc",
      },
      {
         path: '/certification/management-cert',
         title: "경영인증",
         desc: `
            벤처기업<br/>
            이노비즈<br/>
            기업부설연구소<br/>
            메인비즈
         `,
         bgColor: "#067abe",
      },
   ]
   const logoInfo = [
      {
         img: contactLogo01,
         path: "/certification/procurement?0",
      },
      {
         img: contactLogo02,
         path: "/certification/procurement?1",
      },
      {
         img: contactLogo03,
         path: "/certification/procurement?3",
      },
      {
         img: contactLogo04,
         path: "/certification/procurement?4",
      },
      {
         img: contactLogo05,
         path: "/certification/procurement?5",
      },
      {
         img: contactLogo06,
         path: "/certification/procurement?8",
      },
      {
         img: contactLogo07,
         path: "/certification/quality-cert?0",
      },
      {
         img: contactLogo08,
         path: "/certification/quality-cert?1",
      },
      {
         img: contactLogo09,
         path: "/certification/quality-cert?2",
      },
      {
         img: contactLogo10,
         path: "/certification/quality-cert?3",
      },
      {
         img: contactLogo11,
         path: "/certification/quality-cert?4",
      },
      {
         img: contactLogo12,
         path: "/certification/quality-cert?5",
      },
      {
         img: contactLogo13,
         path: "/certification/quality-cert?6",
      },
      {
         img: contactLogo14,
         path: "/certification/quality-cert?7",
      },
      {
         img: contactLogo15,
         path: "/certification/quality-cert?8",
      },
      {
         img: contactLogo16,
         path: "/certification/quality-cert?9",
      },
      {
         img: contactLogo17,
         path: "/certification/quality-cert?10",
      },
      {
         img: contactLogo18,
         path: "/certification/tech-cert?0",
      },
      {
         img: contactLogo19,
         path: "/certification/tech-cert?1",
      },
      {
         img: contactLogo20,
         path: "/certification/tech-cert?2",
      },
      {
         img: contactLogo21,
         path: "/certification/tech-cert?3",
      },
      {
         img: contactLogo22,
         path: "/certification/management-cert?0",
      },
      {
         img: contactLogo23,
         path: "/certification/management-cert?1",
      },
      {
         img: contactLogo24,
         path: "/certification/management-cert?2",
      },
      {
         img: contactLogo25,
         path: "/certification/management-cert?3",
      },
   ];

   useEffect(() => {
      const handleScroll = () => {
         const animationEl = document.querySelectorAll(".has_ani")

         animationEl.forEach((animationEl) => {
            if (animationEl) {
               const rect = animationEl.getBoundingClientRect();
               if (rect.top >= 0 && rect.bottom <= window.innerHeight / 1.1) {
                  animationEl.classList.add('on');
               } else {
                  animationEl.classList.remove('on');
               }
            }
         })

         const currentScrollTop = document.documentElement.scrollTop;
         const header = document.getElementById("header")
         if (currentScrollTop > 0) {
            header?.classList.add("on")
         } else {
            header?.classList.remove("on")
         }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   // header

   // 폼 데이터 전송 Start
   const [formData, setFormData] = useState<FormDataProps>({
      name: '',
      contact: '',
      company: '',
      email: '',
      message: '',
      agreement: false,
   });
   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value, type } = e.target;

      if (type === 'checkbox') {
         const { checked } = e.target as HTMLInputElement;
         setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked,
         }));
      } else {
         setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
         }));
      }
   };
   const handleSubmit = (e: any) => {
      e.preventDefault();
      if (!formData.agreement) {
         alert('개인정보 수집 및 이용에 동의해야 합니다.');
         return;
      }

      const fetchData = async () => {
         try {
            const response: any = await axios.post(`${process.env.REACT_APP_API_URL}/inquiry`, formData)
            if (response.data.success === true) {
               alert('문의가 등록되었습니다.');
               window.location.reload();
            } else {
               alert('문의 등록에 실패했습니다.');
            }
         } catch (error) {
            console.log(error)
         }
      }
      fetchData();
   };
   // 폼 데이터 전송 End

   // 메인 데이터 Start
   const [mainData, setMainData] = useState<MainDataProps>();
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/main`)
            setMainData(response.data.data)
         } catch (error) {
            console.log(error)
         } finally {
            setLoading(false);
         }
      }
      fetchData();
   }, [])
   // 메인 데이터 End

   const { ref, inView } = useInView({
      triggerOnce: false, // 요소가 한 번 보인 이후에도 감지
      threshold: 1
   });

   return (
      <MainWrap>
         <MainBanner>
            <Swiper
               className="mb_swiper"
               autoplay={{
                  delay: (mainData?.banner[0]?.duration || 4) * 1000,
               }}
               slidesPerView={1}
               modules={[Autoplay]}
            >
               {loading ? (
                  <SwiperSlide className="hide_text">Loading ...</SwiperSlide>
               ) : (
                  mainData && mainData.banner ? (
                     mainData.banner.map((bannerData, index) => (
                        <SwiperSlide className={index === 0 ? "banner_ani" : ""} key={index}>
                           <MbTextWrap className="mb_text_wrap">
                              <span className="text text_left">2002</span>
                              <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(isMobile ? bannerData.mobile_title : bannerData.title) }} className="main_text text-center xl:leading-62 leading-44 xl:text-40 text-24 font-semibold text-white" />
                              <span className="text text_right">Now</span>
                           </MbTextWrap>
                           {isMobile ? (
                              bannerData.mobile_image_type === 0 ? (
                                 <img
                                    className="mb_bg"
                                    src={bannerData.mobile_image}
                                    alt="test"
                                 />
                              ) : (
                                 <video
                                    className="mb_bg"
                                    autoPlay={true}
                                    loop
                                    muted
                                    playsInline
                                 >
                                    <source src={bannerData.mobile_image} title="메인배너 동영상" />
                                 </video>
                              )
                           ) : (
                              bannerData.image_type === 0 ? (
                                 <img
                                    className="mb_bg"
                                    src={bannerData.image}
                                    alt="test"
                                 />
                              ) : (
                                 <video
                                    className="mb_bg"
                                    autoPlay={true}
                                    loop
                                    muted
                                    playsInline
                                 >
                                    <source src={bannerData.image} title="메인배너 동영상" />
                                 </video>
                              )
                           )}
                        </SwiperSlide>
                     ))
                  ) : (
                     <SwiperSlide>배너가 없습니다.</SwiperSlide>
                  )
               )}
            </Swiper>
            <NoticeArea className="container">
               <NoticeBox className="xl:gap-32 gap-8" bgcolor="rgba(240,96,0,0.4)">
                  <h3 className="xl:text-32 font-semibold">NOTICE</h3>
                  <Swiper
                     className="main_swiper w-full h-full"
                     direction={'vertical'}
                     autoplay={{ delay: 3000 }}
                     modules={[Autoplay]}
                  >
                     {mainData && mainData.notice ? (
                        mainData.notice.map((notice, index) => (
                           <SwiperSlide
                              className="text max_line1 xl:text-18 text-12 leading-20 flex justify-start items-center"
                              key={index}
                           >
                              <Link
                                 className="max_line1"
                                 to={`/customer/notice/${notice.id}?page=1&search=`}
                              >
                                 <p className="flex-1 max_line1 xl:pr-24 pr-16">{notice.title}</p>
                              </Link>
                           </SwiperSlide>
                        ))
                     )
                        : (
                           <SwiperSlide className="text xl:text-18 text-12 leading-20 flex justify-start items-center">게시글이 없습니다.</SwiperSlide>
                        )}
                  </Swiper>
               </NoticeBox>
               <NoticeBox className="xl:gap-32 gap-8" bgcolor="rgba(5,37,201,0.4)">
                  <h3 className="leading-1em xl:text-32 text-16 font-semibold">NEWS</h3>
                  <Swiper
                     className="main_swiper w-full h-full"
                     direction={'vertical'}
                     autoplay={{ delay: 3000 }}
                     modules={[Autoplay]}
                  >
                     {mainData && mainData.news ? (
                        mainData.news.map((news, index) => (
                           <SwiperSlide
                              className="text max_line1 xl:text-18 text-12 leading-20 flex justify-start items-center"
                              key={index}
                           >
                              <Link
                                 className="max_line1"
                                 to={`/customer/information/${news.id}?page=1&search=`}
                              >
                                 <p className="flex-1 max_line1 xl:pr-24 pr-16">{news.title}</p>
                              </Link>
                           </SwiperSlide>
                        ))
                     ) : (
                        <SwiperSlide className="text xl:text-18 text-12 leading-20 flex justify-start items-center">게시글이 없습니다.</SwiperSlide>
                     )}
                  </Swiper>
               </NoticeBox>
            </NoticeArea>
         </MainBanner>
         <AboutWrap className="container xl:mt-160 mt-60">
            <div>
               <div>
                  <SubTitle className="xl:mb-24 mb-16">ABOUT</SubTitle>
                  <h3 className="xl:text-left text-center xl:leading-62 xl:text-44 text-24 font-bold xl:mb-32 mb-24">
                     우수조달제품인증 <br />
                     무엇일까요?
                  </h3>
               </div>
               <p className="desc_text xl:text-left text-center xl:leading-28 xl:text-18">
                  우수제품 제도는 조달물자의 품질향상을 위해 <br className="xl:block hidden" />
                  96년에 도입하여 중소기업이 생산한 제품 중 <br className="xl:block hidden" />
                  기술 및 품질이 우수한 제품을 대상으로 엄정한 평가를 통해 <br className="xl:block hidden" />
                  우수제품으로 지정하는 제도입니다.
               </p>
               <div className="link_wrap xl:mt-48 mt-40">
                  <Link to="/certification/procurement" className="pb-16 xl:mb-32">
                     <span className="leading-1em xl:text-24 text-16">우수조달제품인증이란?</span>
                     <i className="transition ease-out icon xi-plus-thin xl:text-20"></i>
                  </Link>
                  <Link to="/customer/contact" className="pb-16">
                     <span className="leading-1em xl:text-24 text-16">컨설팅 문의하기</span>
                     <i className="transition ease-out icon xi-plus-thin xl:text-20"></i>
                  </Link>
               </div>
            </div>
            <img className="img_about xl:mt-0 mt-24" src={about01} alt="about 관련(악수하는 이미지)" />
         </AboutWrap>
         <Performance className="xl:pt-120 pt-60 xl:pb-120 pb-60 xl:mt-120 mt-60">
            <div className="container">
               <SubTitle className="leading-1em xl:mb-24 mb-16 text-center xl:text-18 text-12">PERFORMANCE</SubTitle>
               <h3 className="text-center xl:leading-62 leading-32 xl:text-44 text-20 font-bold mb-40">
                  최고 실적과 함께하는 인증의 명가
               </h3>
               <div className="flex xl:flex-row flex-col justify-center items-center xl:gap-16 gap-8">
                  <span className="xl:text-24">우수조달컨설팅은 </span>
                  <div className="box_point leading-1em xl:text-28 font-bold xl:p-16">총 8천개 사</div>
                  <span className="xl:text-24">성공실적을 보유하고 있습니다.</span>
               </div>
            </div>
            <ul className="container performance_items xl:mt-100 mt-40">
               {
                  performanceItems.map((performanceItem, index) => (
                     <li key={index}>
                        <div className="icon_wrap xl:mb-24 mb-16">
                           <img className="icon" src={performanceItem.img} alt="" />
                        </div>
                        <p className="text-center leading-1em xl:text-20">{performanceItem.title}</p>
                        <p className="counter_wrap text-center xl:text-20 xl:pt-16 pt-8">
                           <span ref={ref} className="leading-1em xl:text-44 font-bold xl:pr-4">
                              {inView && <CountUp start={0} end={performanceItem.count} duration={1.2} />}
                           </span>개
                        </p>
                     </li>
                  ))
               }
            </ul>
            <div className="enterprise_wrap xl:mt-160 mt-80">
               <p className="text-center xl:text-24 font-bold">우수조달컨설팅에서 인증성공한 업체들</p>
               <div className="enterprise_ul_wrap xl:mt-80 mt-24">
                  <ul className="enterprise_ul">
                     {enterpriseItems.map((enterpriseItem, index) => (
                        <li key={index}><img className="logo" src={enterpriseItem} alt="업체 로고" /></li>
                     ))}
                  </ul>
                  <ul className="enterprise_ul">
                     {enterpriseItems.map((enterpriseItem, index) => (
                        <li key={index}><img className="logo" src={enterpriseItem} alt="업체 로고" /></li>
                     ))}
                  </ul>
               </div>
            </div>
         </Performance>
         <Certification className="xl:pt-120 pt-80 xl:pb-120 pb-80">
            <div>
               <SubTitle className="leading-1em xl:mb-24 text-center xl:text-18 text-12">CERTIFICATION</SubTitle>
               <h3 className="text-center leading-1em xl:text-44 text-24 font-bold xl:mt-0 mt-16 xl:mb-80 mb-26">
                  취급 인증분야
               </h3>
            </div>
            <div className="container items_wrap grid xl:grid-cols-4 grid-cols-2 xl:gap-48 gap-24">
               {CertificationInfo.map((CertificationInfo, index) => (
                  <Link
                     to={CertificationInfo.path}
                     className="item"
                     key={index}
                  >
                     <div
                        style={{ boxShadow: `inset 0px 0px 0px 6px ${CertificationInfo.bgColor}` }}
                        className="title_wrap flex flex-col justify-center items-center"
                     >
                        <p
                           style={{ color: CertificationInfo.bgColor }}
                           className="leading-1em xl:text-16 text-13 font-bold"
                        >
                           0{index + 1}
                        </p>
                        <strong className="leading-1em xl:text-24 text-18 font-bold mt-8">{CertificationInfo.title}</strong>
                     </div>
                     <p
                        className="xl:leading-28 xl:text-18 text-13 xl:mt-24 mt-16 text-subColor03"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(CertificationInfo.desc) }}
                     />
                  </Link>
               ))}
            </div>
            <p className="text-center xl:text-24 text-18 xl:mt-80 mt-60">
               <strong>우수조달컨설팅</strong>은 <strong>다양한 인증분야</strong>에서 <strong>확실한 컨설팅 결과</strong>를 보장합니다.
            </p>
         </Certification>
         <Competitivness className="xl:pt-80 pt-60">
            <div className="container">
               <div>
                  <SubTitle className="leading-1em xl:text-18 text-12 xl:mb-24 mb-16">PERFORMANCE</SubTitle>
                  <h3 className="xl:text-left text-center xl:leading-62 xl:text-44 text-24 font-bold xl:mb-40 mb-24">
                     우수조달컨설팅의<br />
                     차별화된 경쟁력
                  </h3>
                  <p className="xl:text-left text-center xl:leading-30 leading-24 xl:text-18 text-14" >
                     23년간의 빅데이터 활용으로<br className="xl:block hidden" />
                     어떻게 하면 합격하고 어떻게 하면<br className="xl:block hidden" />
                     불합격하는지를 쉽게 알 수 있습니다.
                  </p>
               </div>
               <div className="scroll_wrap">
                  <ul className="competitivness_item_wrap xl:pt-64 pt-24 xl:pr-10 xl:pl-10 xl:pb-10 pb-24">
                     {CompetitivnessItems.map((CompetitivnessItem, index) => (
                        <li className="item" key={index}>
                           <p className="count xl:text-16 font-bold">{CompetitivnessItem.count}</p>
                           <h4 className="title xl:leading-36 xl:text-24 text-18 font-bold mt-16 mb-24" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(CompetitivnessItem.title) }} />
                           <p className="desc leading-24 xl:text-16 text-14" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(CompetitivnessItem.desc) }} />
                        </li>
                     ))}
                  </ul>
               </div>
            </div>
         </Competitivness>
         <CorporateGrade>
            <div className="container xl:pt-160 pt-60 xl:pb-220 pb-60">
               <div>
                  <SubTitle className="leading-1em text-center xl:text-18 text-12 xl:mb-24 mb-16">CORPORATE GRADE</SubTitle>
                  <h3 className="text-center xl:leading-62 leading-32 xl:text-44 text-24 font-bold xl:mb-32 mb-24">
                     국내 유일 "A0" 기업신용평가등급
                  </h3>
                  <p className="sub_title text-center xl:leading-30 leading-24 xl:text-18">우수조달컨설팅은 기업신용등급으로 회사의 실력과 신뢰를 한번 더 증명했습니다.</p>
               </div>
               <div className="item_wrap xl:flex block xl:justify-center xl:mt-100 mt-40">
                  <div className="item ml-auto mr-auto">
                     <p className="title leading-1em xl:text-22 text-14 font-bold">A0 등급이란?</p>
                     <img className="main_img xl:mt-56 mt-32" src={corporategrade01} alt="A0 등급이란?" />
                  </div>
                  <div className="item ml-auto mr-auto xl:mt-0 mt-40">
                     <p className="title leading-1em xl:text-22 text-14 font-bold">월 실적 건수</p>
                     <img className="main_img xl:mt-56 mt-32" src={corporategrade02} alt="월 실적 건수" />
                  </div>
               </div>
               <p className="xl:leading-30 leading-26 xl:text-24 text-18 text-center xl:mt-120 mt-40"><strong className="point">우수조달컨설팅</strong>은 확실한 신용과 빅데이터를 활용하여 정확한 컨설팅을 진행합니다. </p>
            </div>
         </CorporateGrade>
         <Strength>
            <div className="container xl:pb-160 pb-60">
               <div>
                  <SubTitle className="xl:mb-24 mb-16">STRENGTH</SubTitle>
                  <h3 className="xl:text-left text-center xl:leading-62 xl:text-44 text-24 font-bold xl:mb-80 mb-24">
                     우수제품 최초지정 5년 후 매출은<br className="xl:block hidden" />
                     평균 9.4배 증가
                  </h3>
               </div>
               <img className="w-full" src={strength} alt="그래프" />
               {/* <div>
                  <div className="item_graph has_ani">
                     <div className="graph_wrap">
                        <p className="xl:text-24"><strong className="xl:pr-4">935.5</strong>%</p>
                        <div className="hide_text graph">그래프</div>
                     </div>
                     <p className="text-right xl:text-20 text-14 font-bold xl:mt-16 mt-8">5년차</p>
                  </div>
                  <div className="item_graph xl:mt-40 mt-24 has_ani">
                     <div className="graph_wrap">
                        <p className="xl:text-24"><strong className="xl:pr-4">637.7</strong>%</p>
                        <div className="hide_text graph">그래프</div>
                     </div>
                     <p className="text-right xl:text-20 text-14 font-bold xl:mt-16 mt-8">3년차</p>
                  </div>
                  <div className="item_graph xl:mt-40 mt-24 has_ani">
                     <div className="graph_wrap">
                        <p className="xl:text-24"><strong className="xl:pr-4">236.0</strong>%</p>
                        <div className="hide_text graph">그래프</div>
                     </div>
                     <p className="text-right xl:text-20 text-14 font-bold xl:mt-16 mt-8">1년차</p>
                  </div>
               </div> */}
               <p className="desc text-right xl:leading-28 leading-24 xl:text-16 text-13 xl:mt-40 mt-24">
                  2013년도에 우수조달물품으로 최초 지정된 70개사의 5년간 관급 매출변화<br className="xl:block hidden" />
                  (기준: 2012년도 관급 매출)
               </p>
            </div>
            <div className="container content02 xl:pb-120 pb-60">
               <div>
                  <SubTitle className="text-center leading-1em xl:text-18 text-12 xl:mb-24 mb-16">STRENGTH</SubTitle>
                  <h3 className="text-center xl:leading-62 leading-32 xl:text-44 text-24 font-bold xl:mb-80 mb-24">
                     확실한 착수금 사용과 전문적인 컨설팅
                  </h3>
               </div>
               <div className="item_wrap xl:flex block xl:gap-90">
                  <div className="item ml-auto mr-auto">
                     <p className="text_before text text-center xl:leading-32 leading-24 xl:text-24 text-14 font-medium text-subColor03 bg-white">
                        착수금을 받은 후<br />
                        컨설팅 진행사항에 맞게 사용하지 않음
                     </p>
                     <i className="xi-angle-down-thin block text-center xl:text-30 xl:pt-32 pt-16 xl:pb-32 pb-16 text-subColor03"></i>
                     <p className="text_after text xl:text-24 text-18 font-medium bg-subColor01"><strong className="text-white">15% 이하</strong>의 합격률</p>
                     <p className="other text-center xl:text-24 font-bold xl:mt-48 mt-24 xl:pb-0 pb-40 xl:mb-0 mb-40">&#60;타 컨설팅사&#62;</p>
                  </div>
                  <div className="item ml-auto mr-auto">
                     <p className="text_before text xl:leading-32 leading-24 text-center xl:text-24 text-14 font-medium text-pointColor01 bg-white">
                        52개의 컨설팅 진행단계로<br />
                        단계에 맞게 착수금을 사용함
                     </p>
                     <i className="xi-angle-down-thin block text-center xl:text-30 xl:pt-32 pt-16 xl:pb-32 pb-16 text-subColor03"></i>
                     <p className="text_after text xl:text-24 text-18 font-medium bg-pointColor01"><strong className="text-white">95% 이상</strong>의 합격률</p>
                     <p className="text-center xl:text-24 font-bold xl:mt-48 mt-24">&#60;우수조달컨설팅&#62;</p>
                  </div>
               </div>
            </div>
         </Strength>
         <Youtube>
            <div className="container xl:pt-120 pt-60">
               <div>
                  <SubTitle className="leading-1em text-center xl:text-18 text-12 xl:mb-24 mb-16">YOUTUBE</SubTitle>
                  <h3 className="text-center xl:leading-62 leading-32 xl:text-44 text-24 font-bold">
                     영상으로 만나는 우수조달컨설팅
                  </h3>
                  <p className="xl:block hidden text-center xl:text-18 xl:mt-32 xl:mb-40 text-subColor03">우수조달인증에 대한 정보를 동영상으로 만나보세요.</p>
               </div>
               <Swiper
                  className="main_swiper swiper xl:pb-48 xl:mt-0 mt-24"
                  breakpoints={{
                     1024: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                     },
                     0: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                     },
                  }}
                  autoplay={{
                     delay: 2500,
                     disableOnInteraction: false,
                  }}
                  pagination={true}
                  modules={[Autoplay, Pagination]}
               >
                  {mainData && mainData.youtube ? (
                     mainData.youtube.length > 0 ? (mainData.youtube.map((youtube, index) => (
                        <SwiperSlide className="slide" key={index}>
                           <a
                              href={`https://www.youtube.com/watch?v=${youtube.video_id}`}
                              className="btn_a"
                              target="_blank"
                              rel="noreferrer"
                           >
                              <img className="thumbnail xl:mb-20 mb-16" src={`https://img.youtube.com/vi/${youtube.video_id}/maxresdefault.jpg`} alt="" />
                              <h4 className="xl:leading-28 leading-22 xl:text-20 text-14 font-bold">{youtube.title}</h4>
                              <p className="leading-1em xl:text-16 text-12 xl:mt-16 mt-8 text-subColor04">{youtube.created_at_formatted}</p>
                           </a>
                        </SwiperSlide>
                     ))) : (
                        <SwiperSlide className="leading-1em xl:text-22 xl:mt-48 text-subColor04">게시글이 없습니다.</SwiperSlide>
                     )
                  ) : (
                     <SwiperSlide>게시글이 없습니다.</SwiperSlide>
                  )}
               </Swiper>
            </div>
         </Youtube>
         <Notice className="container xl:pt-160 pt-60 xl:pb-120 pb-60">
            <div className="xl:flex block justify-between items-end xl:mb-40">
               <div className="xl:w-auto w-full">
                  <SubTitle className="xl:text-left leading-1em text-center xl:text-18 text-12 xl:mb-24 mb-16">NOTICE</SubTitle>
                  <h3 className="xl:text-left text-center xl:leading-62 leading-32 xl:text-44 text-24 font-bold">
                     우수조달컨설팅의<br />
                     최신 소식을 전해드립니다
                  </h3>
               </div>
               <Link to="/customer/notice" className="btn_a type_01 pc xl:ml-0 ml-auto xl:mt-0 mt-16 xl:mb-0 mb-16">
                  <p className="txt">View More</p>
                  <i className="xi-long-arrow-right"></i>
               </Link>
            </div>
            <Swiper
               className="announcement_swiper main_swiper swiper xl:pb-48 pb-32"
               breakpoints={{
                  1024: {
                     slidesPerView: 3,
                     spaceBetween: 32,
                  },
                  0: {
                     slidesPerView: 1,
                     spaceBetween: 10,
                  },
               }}
               autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
               }}
               pagination={true}
               modules={[Autoplay, Pagination]}
            >
               {mainData && mainData.announcement.length > 0 ? (
                  mainData.announcement.map((announcement, index) => (
                     <SwiperSlide className="slide" key={index}>
                        <Link className="btn_a xl:pt-32 xl:pb-32 pt-24 pb-24" to={`/customer/notice/${announcement.id}`}>
                           <h4 className="title max_line2 xl:leading-32 leading-26 xl:text-24 text-16 font-bold">{announcement.title}</h4>
                           <p
                              className="announcement_contents max_line3 xl:pt-24 pt-16"
                              dangerouslySetInnerHTML={{
                                 __html: sanitize(announcement.content, {
                                    FORBID_TAGS: ['p', 'br', 'img', 'video'],
                                    ALLOWED_ATTR: []
                                 })
                              }}
                           />
                           <p className="xl:leading-28 leading-24 xl:text-16 text-14 xl:pt-32 pt-24 text-subColor04">{announcement.created_at_formatted}</p>
                        </Link>
                     </SwiperSlide>
                  ))
               ) : (
                  <SwiperSlide>게시글이 없습니다.</SwiperSlide>
               )}
            </Swiper>
            <div className="container content02 xl:mt-120 mt-60">
               <ul className="item_wrap xl:grid xl:grid-cols-3 xl:gap-32">
                  <li className="item">
                     <Link
                        to="/certification/procurement"
                        className="flex items-center gap-16 xl:pt-24 xl:pr-32 xl:pb-24 xl:pl-32 pt-14 pb-14 pl-30 pr-30"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img className="icon_main" src={notice01} alt="" />
                        <h5 className="xl:leading-32 leading-24 xl:text-22 text-14 font-medium text-subColor09">
                           우수조달제품인증<br />
                           취득 전 필독사항
                        </h5>
                        <div className="icon_arrow_wrap bg-subColor09">
                           <i className="xi-arrow-right xl:text-18 text-white"></i>
                        </div>
                     </Link>
                  </li>
                  <li className="item xl:mt-0 mt-16">
                     <Link
                        to="/customer/notice/1?page=1&search="
                        className="flex items-center gap-16 xl:pt-24 xl:pr-32 xl:pb-24 xl:pl-32 pt-14 pb-14 pl-30 pr-30"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img className="icon_main" src={notice02} alt="" />
                        <h5 className="xl:leading-32 leading-24 xl:text-22 text-14 font-medium text-pointColor01">
                           우수조달제품인증<br />
                           준비는 특허부터
                        </h5>
                        <div className="icon_arrow_wrap bg-pointColor01">
                           <i className="xi-arrow-right xl:text-18 text-white"></i>
                        </div>
                     </Link>
                  </li>
                  <li className="item xl:mt-0 mt-16">
                     <Link
                        to="/customer/notice/25?page=1&search="
                        className="flex items-center gap-16 xl:pt-24 xl:pr-32 xl:pb-24 xl:pl-32 pt-14 pb-14 pl-30 pr-30"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img className="icon_main" src={notice03} alt="" />
                        <h5 className="xl:leading-32 leading-24 xl:text-22 text-14 font-medium text-pointColor02">
                           우수조달인증획득<br />
                           설명회 개최
                        </h5>
                        <div className="icon_arrow_wrap bg-pointColor02">
                           <i className="xi-arrow-right xl:text-18 text-white"></i>
                        </div>
                     </Link>
                  </li>
               </ul>
            </div>
         </Notice>
         <ContactUs className='section10 contact_us'>
            <div className='item01 item'>
               <div>
                  <p className='xl:text-18 text-13 text-white'>CONTACT US</p>
                  <h3 className='tit xl:text-40 text-40 font-bold xl:mt-24 mt-16 text-white'>문의하기</h3>
                  <p className='xl:leading-26 leading-20 xl:text-17 text-13 font-light xl:mt-32 mt-24 text-white'>
                     우수조달컨설팅에 궁금한 점을 남겨주세요.<br className="xl:block hidden" />
                     저희 회사는 언제나 고객의 목소리에 귀 기울이고 있습니다.
                  </p>
                  <div className="xl:mt-40 mt-32">
                     <div className="icon_wrap xl:mb-16 mb-8">
                        <i className="icon xi-call xl:text-24 text-16 text-pointColor01"></i>
                        <p className='xl:text-17 text-13 font-medium text-white'>031-234-2870</p>
                     </div>
                     <div className="icon_wrap">
                        <i className="icon xi-maker xl:text-24 text-16 text-pointColor01"></i>
                        <p className='xl:text-17 text-13 font-medium text-white'>경기도 수원시 영통구 신원로 55 테크트리영통 지식산업센터 913~914호</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='item02 item'>
               <form onSubmit={handleSubmit} className="inquiry_from">
                  <fieldset className="inquiry_fieldset">
                     <legend className="hide">contact us</legend>
                     <div className="input_box_wrap xl:mb-32 mb-24">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="name" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">성함 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="성함을 입력하세요."
                              required
                           />
                        </div>
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="contact" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">연락처 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="tel"
                              id="contact"
                              name="contact"
                              value={formData.contact}
                              onChange={handleChange}
                              placeholder="연락처를 입력하세요."
                              required
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap xl:mb-32 mb-24">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="company" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">회사명</label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="company"
                              id="company"
                              name="company"
                              value={formData.company}
                              onChange={handleChange}
                              placeholder="회사명을 입력하세요."
                           />
                        </div>
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="email" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">이메일 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="이메일을 입력하세요."
                              required
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="inquiry" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">문의내용</label>
                           <textarea
                              className="input_box xl:text-18 text-14"
                              id="inquiry"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              placeholder="문의내용을 기재해주세요."
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap agreement xl:mt-32 mt-24">
                        <div className="privacy_agree_wrap">
                           <input
                              id="privacy_agree"
                              name="agreement"
                              value="1"
                              checked={formData.agreement}
                              onChange={handleChange}
                              required
                              type="checkbox"
                           />
                           <label className='xl:text-17 text-13' htmlFor="privacy_agree">개인정보 수집 및 이용에 대한 동의 (필수)</label>
                           <button onClick={() => { openPrivacyAgreement() }} className="view_all xl:text-17 text-13" type="button">전문보기</button>
                        </div>
                     </div>
                     <button className="xl:mt-56 mt-32 btn type01 font-bold text-white" type="submit">문의 등록</button>
                  </fieldset>
               </form>
            </div>
         </ContactUs>
         <LogoBanner>
            <ul className="logo_wrap flex xl:gap-32 gap-16 xl:pl-32 pl-16">
               {logoInfo.map((logoInfo, index) => (
                  <li key={index}>
                     <Link to={logoInfo.path}>
                        <img src={logoInfo.img} alt="logo" />
                     </Link>
                  </li>
               ))}
            </ul>
            <ul className="logo_wrap flex xl:gap-32 gap-16 xl:pl-32 pl-16">
               {logoInfo.map((logoInfo, index) => (
                  <li key={index}>
                     <Link to={`${logoInfo.path}?${index}`}>
                        <img src={logoInfo.img} alt="logo" />
                     </Link>
                  </li>
               ))}
            </ul>
         </LogoBanner>
         <PrivacyAgreement
            open={privacyShow}
            close={closePrivacy}
         />
         <Popup />
      </MainWrap>
   )
}

export default Home;