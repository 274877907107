import React from 'react'
import styled from 'styled-components'
import DOMPurify, { sanitize } from 'dompurify'
import { Link } from 'react-router-dom'

// images
import icon01 from "../images/common/icon/icon01.png"
import icon02 from "../images/common/icon/icon02.png"
import icon03 from "../images/common/icon/icon03.png"
import icon04 from "../images/common/icon/icon04.png"
import icon05 from "../images/common/icon/icon05.png"
import icon06 from "../images/common/icon/icon06.png"

// import icon07 from "../images/common/icon/icon07.png"
// import icon08 from "../images/common/icon/icon08.png"
// import icon09 from "../images/common/icon/icon09.png"
// import icon10 from "../images/common/icon/icon10.png"
// import icon11 from "../images/common/icon/icon11.png"
import icon12 from "../images/common/icon/icon12.png"

const DistinctionWrap = styled.div`
   & .items_wrap{
      width: 100%;
      max-width: 1020px;
      border-top: 2px solid var(--subColor01);
      border-bottom: 2px solid var(--subColor01);
      & .icon_wrap{
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100px;
         height: 100px;
         border-radius: 50%;
         background-color: #fcfcff;
            & .icon{
            width: auto;
            height: 100%;
            max-width: 50px;
            max-height: 50px;
         }
      }
      & .item{
         border-bottom: 1px solid var(--subColor05);
         & .desc{
            list-style: disc;
         }
      }
      & .item:last-of-type{
         border-bottom: 0px none;
      }
   }
   @media screen and (max-width: 1280px){
      .items_wrap{
         .icon_wrap{
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
            .icon{
               width: auto;
               height: 27px;
            }
         }
      }
   }
`
const Distinction = () => {

   const distinctionData = [
      {
         image: icon01,
         count: "01",
         title: "성공률 95% 보장",
         desc: [
            "우수조달인증 조달청 통계 성공률 : 15~25%",
            "우수조달컨설팅본사의 조달청 통계 성공률 : 90~95%",
         ]
      },
      {
         image: icon02,
         count: "02",
         title: "총 8천개사 성공",
         desc: [
            "수만 개 업체 발표 예행연습 경험을 토대로 우수조탈컨설팅만의 성공합격 공식이 축적되어 있습니다.",
         ]
      },
      {
         image: icon03,
         count: "03",
         title: "세부적인 컨설팅 절차 보유",
         desc: [
            "일정별, 프로세스별로 세부적인 일정 및 컨설팅 절차로 성공률을 높힙니다.",
         ]
      },
      {
         image: icon04,
         count: "04",
         title: "발표연습",
         desc: [
            "전 심사위원이었던 대표님께서 직접 발표연습 코칭을 진행하시며<br class='xl:block hidden' /> 100번 이상의 예행연습을 통해 최상의 발표를 도출해냅니다.",
         ]
      },
      {
         image: icon05,
         count: "05",
         title: "빅데이터 보유",
         desc: [
            "성공사례 분석보고서 : 성공적인 프로젝트에서 시각적으로 전달하고 기록 유지",
            "발표영상 : 고객에게 발표 내용을 시각적으로 전달하고 기록 유지",
            "서류파일 작성 : 기업의 제안서 및 문서 작성에 도움을 주고 효율성을 향상",
            "시험성적서 : 고객이 수행한 시험결과를 기록하고 성적표를 발급",
         ]
      },
      {
         image: icon06,
         count: "06",
         title: "최우량급 신용등급",
         desc: [
            "A0의 기업평가등급과 CF1의 현금흐름등급으로 신용능력이 최우량급이며 환경변화와 현금흐름창출능력이 최상급인 상위 3% 기업입니다.",
         ]
      },
   ]

   return (
      <DistinctionWrap className='distinction container xl:flex xl:gap-30 xl:pt-0 pt-60 xl:mb-120 mb-60'>
         <h3 className='xl:leading-48 leading-1em xl:text-32 text-24 font-bold xl:pb-0 pb-24'>
            우수조달컨설팅의&nbsp;<br className='xl:block hidden' />
            차별성
         </h3>
         <ul className='items_wrap'>
            {
               distinctionData.map((distinctionData, index) => (
                  <li key={index} className='item flex items-center xl:gap-48 gap-16 xl:pt-32 pt-24 xl:pb-32 pb-24'>
                     <div className='icon_wrap'>
                        <img className='icon' src={distinctionData.image} alt="" />
                     </div>
                     <div>
                        <p className='leading-1em xl:text-16 text-14 font-bold mb-8 text-pointColor01'>{distinctionData.count}</p>
                        <h5 className='leading-em xl:text-24 text-16 font-bold xl:mb-20 mb-16'>{distinctionData.title}</h5>
                        <ul>
                           {
                              distinctionData.desc.map((desc, index) => (
                                 <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }} className='desc xl:text-18 text-12 xl:ml-13 ml-12 xl:mb-12 text-subColor03' key={index}></li>
                              ))
                           }
                        </ul>
                     </div>
                  </li>
               ))
            }
         </ul>
      </DistinctionWrap>
   )
}

const OverviewContainer = styled.section`
   .logo_wrap{
      width: 100px;
      height: 100px;
      border: 1px solid var(--subColor05);
      margin-left: auto;
      margin-right: auto;
      & .logo{
         width: 100%;
         height: auto;
      }
   }
   @media screen and (max-width:1280px){
      .logo_wrap{
         width: 76px;
         height: 76px;
      }
   }
`
interface OverViewProps {
   info: {
      title: string;
      desc: string;
      logo?: string;
   }
}
const Overview: React.FC<OverViewProps> = ({ info }) => {
   return (
      <OverviewContainer className='container xl:mb-120 mb-60'>
         {/* <p className="text-center xl:text-20 text-12 font-bold xl:mb-32 mb-16 text-pointColor01">OVERVIEW</p> */}
         {info.logo && <div className="logo_wrap">
            <img className="logo" src={info.logo} alt="logo" />
         </div>}
         <h3 className="leading-1em text-center xl:text-32 font-bold xl:mt-24 mt-16">{info.title}</h3>
         <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.desc) }} className="text-center xl:leading-30 leading-24 xl:text-20 text-14 mt-24"></p>
      </OverviewContainer>
   )
}

interface CertificationInfoProps {
   info: {
      contents: {
         title: string;
         desc: string;
         bgImg: any;
      }[];
      cont_desc?: string;
      sub_desc?: string;
   };
}
const CertificationInfoContainer = styled.section`
   background-color: #fbfbff;
   & .title{
      white-space: nowrap;
   }
   & .items_wrap{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      width: 1020px;
      & .item{
         max-width: 324px;
         height: 220px;
         background-color: #fff;
         background-size: 70px auto;
         background-position: right 32px bottom 24px;
      }
   }
   @media screen and (max-width:1280px){
      .items_wrap{
         flex-direction: column;
         width: 100%;
         .item {
            max-width: 100%;
            height: auto;
            min-height: 100px;
            background-size: 60px auto;
            background-position: right 30px center;
         }
      }
   }
`
const CertificationInfo: React.FC<CertificationInfoProps> = ({ info }) => {
   return (
      <CertificationInfoContainer className='xl:pt-80 xl:pb-80 pt-60 pb-60'>
         <div className='container xl:flex xl:gap-150'>
            <h3 className='title leading-1em xl:text-32 text-24 font-bold'>인증정보</h3>
            <div className='xl:pt-0 pt-24'>
               <ul className='items_wrap xl:grid flex xl:gap-20 gap-16'>
                  {info.contents.map((info, index) => (
                     <li className='item xl:p-32 p-30' style={{ backgroundImage: `url(${info.bgImg})` }} key={index}>
                        <h4 className='leading-1em xl:text-24 text-16 font-bold'>{info.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.desc) }} className='xl:text-18 text-14 xl:mt-18 mt-8 text-subColor03'></p>
                     </li>
                  ))}
                  {info.cont_desc && <li>
                     <p className='xl:leading-20 xl:text-14 text-12 text-subColor03'>{info.cont_desc}</p>
                  </li>}
               </ul>
               {info.sub_desc && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.sub_desc) }} className='xl:leading-20 xl:text-14 text-12 xl:mt-16 mt-8 text-subColor03'></p>}
            </div>
         </div>
      </CertificationInfoContainer>
   )
}

const BenefitsContainer = styled.section`
   .title{
      white-space: nowrap;
   }
   & .items_wrap{
      grid-template-columns: repeat(3, 1fr);
      & .item{
         overflow: hidden;
         position: relative;
         width: 324px;
         height: 214px;
         border: 1px solid var(--subColor05);
         border-radius: 16px;
         & .num{
            position: absolute;
            bottom: -18px;
            right: 16px;
            color: rgba(93, 179, 230, 0.2);
         }
      }
   }
   & .item_container:first-of-type{
      margin-top: 0px;
   }
   @media screen and (max-width:1280px){
      .items_wrap{
         display: flex;
         flex-direction: column;
         gap: 16px;
         .item{
            width: 100%;
            height: 150px;
            .num{
               right: 35px;
            }
         }
      }
   }
`
interface BenefitsProps {
   info: {
      desc: string
   }[]
}
const Benefits: React.FC<BenefitsProps> = ({ info }) => {

   return (
      <BenefitsContainer className='benefits xl:pt-80 xl:pb-80 pt-60 pb-60'>
         <div className='container xl:flex xl:gap-150'>
            <h3 className='title leading-32 xl:text-32 text-24 font-bold'>인증혜택</h3>
            <ul className='items_wrap xl:grid xl:gap-24 xl:mt-0 mt-24'>
               {
                  info.map((data, index) => (
                     <li className='item xl:p-32 p-30' key={index}>
                        <p className='xl:leading-28 leading-24 xl:text-16 text-14' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.desc) }}></p>
                        <strong className='num leading-1em xl:text-80 text-70 font-bold' > 0{index + 1}</strong>
                     </li>
                  ))
               }
            </ul>
         </div>
      </BenefitsContainer >
   )
}

interface Benefits02Props {
   info: {
      title: string,
      items: string[],
   }[]
}
const Benefits02: React.FC<Benefits02Props> = ({ info }) => {
   return (
      <BenefitsContainer className='benefits xl:pt-80 xl:pb-80 pt-60 pb-60'>
         <div className='container xl:flex xl:gap-150'>
            <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">인증혜택</h3>
            <div>
               {info.map((info, index) => (
                  <div className='item_container xl:mt-32 mt-40' key={index}>
                     <p className='leading-1em xl:text-22 font-semibold mb-16'>{info.title}</p>
                     <ul className='items_wrap xl:grid xl:gap-24 xl:mt-24'>
                        {info.items.map((items, index) => (
                           <li className='item xl:p-32 p-30' key={index}>
                              <p className='xl:text-18 text-14'>{items}</p>
                              <strong className='num leading-1em xl:text-80 text-70 font-bold'>0{index + 1}</strong>
                           </li>
                        ))}
                     </ul>
                  </div>
               ))}
            </div>
         </div>
      </BenefitsContainer>
   )
}

const ConsultingContainer = styled.section`
   background-color: var(--subColor11);
   & .main_img{
      width: 100%;
   }
`
const Consulting = (props: any) => {
   return (
      <ConsultingContainer className='xl:pt-80 xl:pb-80 pt-60 pb-60'>
         <div className='container'>
            <h3 className='leading-1em xl:text-32 text-24 font-bold'>컨설팅 업무 절차</h3>
            <img className='xl:block hidden w-full xl:mt-48 mt-24' src={props.imgSrc} alt="컨설팅 업무 절차" />
            <img className='xl:hidden w-full xl:mt-48 mt-24' src={props.moImgSrc} alt="컨설팅 업무 절차" />
            <p className='xl:text-right text-center xl:text-14 text-12 mt-16 text-subColor03'>※ 컨설팅 계약시 더 자세한 일정 확인 가능</p>
         </div>
      </ConsultingContainer>
   )
}

const ContactContainer = styled.section`
background-color: var(--subColor11);
   & .item01{
      border-right: 1px solid var(--subColor05);
      & .main_img{
         width: 66px;
         height: auto;
      }
   }
   & .item02{
      & .type_disc_wrap li{
         list-style: disc;
      }
   }
   & .item03{
      margin-left: auto;
      & .btn{
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 10px;
         width: 192px;
         height: 50px;
      }
   }
   @media screen and (max-width:1280px){
      .item01{
         border-right: 0px none;
         border-bottom: 1px solid var(--subColor05);
         padding-bottom: 24px;
         margin-bottom: 24px;
         .main_img{
            width: 50px;
            height: auto;
         }
      }
      .item03{
         .btn{
            width: 100%;
            height: 40px;
         }
      }
   }
   @media screen and (min-width:768px){
      .item03{
         .btn_tel{
            display: none;
         }
      }
   }
`
const ContactBanner = () => {
   return (
      <ContactContainer className='container xl:flex xl:pt-32 xl:pb-32 xl:pl-48 xl:pr-48 xl:mt-80 pt-60 pb-60 mt-60'>
         <div className='item01 flex items-center xl:gap-32 gap-16 xl:pr-40 xl:mr-40'>
            <img className='main_img' src={icon12} alt="" />
            <p className='xl:leading-36 xl:text-24 font-bold'>
               우수조달컨설팅에 <br className='xl:block hidden' />
               <strong className='text-pointColor01'>문의하기</strong>
            </p>
         </div>
         <div className='item02 flex xl:gap-16 gap-30'>
            <ul className='type_disc_wrap'>
               <li className='leading-1em xl:text-16 text-14 font-bold xl:mb-12 mb-16 xl:ml-0 ml-12 text-pointColor01'>이메일 주소</li>
               <li className='leading-1em xl:text-16 text-14 font-bold xl:mb-12 mb-16 xl:ml-0 ml-12 text-pointColor01'>대표번호</li>
               <li className='leading-1em xl:text-16 text-14 font-bold xl:mb-12 mb-16 xl:ml-0 ml-12 text-pointColor01'>대표자휴일/주말</li>
            </ul>
            <ul>
               <li className='leading-1em xl:text-16 text-14 xl:mb-12 mb-16 '>ceo@promas.co.kr</li>
               <li className='leading-1em xl:text-16 text-14 xl:mb-12 mb-16 '>031-234-2870</li>
               <li className='leading-1em xl:text-16 text-14 xl:mb-12 mb-16 '>010-4611-2870</li>
            </ul>
         </div>
         <div className='item03 xl:flex items-center xl:gap-16 xl:mt-0 mt-24'>
            <Link className='btn_mail btn xl:text-16 text-14 xl:pt-16 xl:pr-24 xl:pb-16 xl:pl-24 text-white bg-pointColor03' to="/customer/contact">
               <i className='xi-mail text-white'></i>
               온라인 문의하기
            </Link>
            <a className='btn_tel btn xl:text-16 text-14 xl:pt-16 xl:pr-24 xl:pb-16 xl:pl-24 xl:mt-0 mt-8 text-white bg-pointColor01' href="tel:031-234-2870">
               <i className='xi-call text-white'></i>
               대표번호 전화연결
            </a>
         </div>
      </ContactContainer>
   )
}

const CheckListWrap = styled.section`
   & .sub_title:first-of-type{
      margin-top: 0px;
   }
   & .item_wrap{
      width:100%;
      max-width: 1020px;
      margin-left: auto;
      .list:first-of-type{
         margin-top: 0px;
         .item{
            width: 100%;
         }
      }
   }
`
interface CheckListProps {
   info: {
      positive?: {
         title: string,
         desc: {
            desc_title: string,
            desc_text: string[];
         }[],
      },
      negative?: {
         title: string,
         icon: string,
         desc: {
            desc_title: string,
            desc_text: string[];
         }[],
      },
   }
}
const CheckList: React.FC<CheckListProps> = ({ info }) => {
   return (
      <CheckListWrap>
         <section className="target_wrap container xl:pt-80 xl:pb-100 pt-60 pb-60">
            {info.positive &&
               <div className="xl:flex xl:gap-80">
                  <h3 className="title whitespace-nowrap leading-1em xl:text-32 text-24 font-bold">{info.positive.title}</h3>
                  <ul className="item_wrap xl:mt-0 mt-24">
                     {info.positive.desc.map((desc, index) => (
                        <li className='list xl:mt-24 mt-40' key={index}>
                           {desc.desc_title && <p className='sub_title xl:leading-1em xl:text-22 text-14 font-semibold xl:mb-24 xl:mt-48 mb-16' key={index}>{desc.desc_title}</p>}
                           {desc.desc_text.map((desc_text, index) => (
                              <div className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 p-16 xl:mb-12 mb-10 bg-subColor12" key={index}>
                                 <i className="xi-check-circle-o xl:text-18 text-14 mt-4 text-pointColor01"></i>
                                 <p className="xl:leading-30 xl:text-18 text-14" dangerouslySetInnerHTML={{ __html: sanitize(desc_text) }}></p>
                              </div>
                           ))}
                        </li>
                     ))}
                  </ul>
               </div>}
            {info.negative &&
               <div className="xl:flex xl:gap-80 xl:mt-80 mt-60">
                  <h3 className="title whitespace-nowrap leading-1em xl:text-32 text-24 font-bold">{info.negative.title}</h3>
                  <ul className="item_wrap xl:mt-0 mt-24">
                     {info.negative.desc.map((desc, descIndex) => (
                        <li className='list xl:mt-24 mt-40' key={descIndex}>
                           {desc.desc_title && <p className="sub_title xl:leading-1em xl:text-22 text-14 font-semibold xl:mb-24 xl:mt-48 mb-16" key={descIndex}>{desc.desc_title}</p>}
                           {desc.desc_text.map((desc_text, textIndex) => (
                              <div className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 p-16 xl:mb-12 mb-16 bg-subColor12" key={textIndex}>
                                 <i className={`${info.negative && info.negative.icon} xl:text-18 text-14 mt-4 text-subColor09`}></i>
                                 <p className="xl:leading-30 xl:text-18 text-14" dangerouslySetInnerHTML={{ __html: sanitize(desc_text) }}></p>
                              </div>
                           ))}
                        </li>
                     ))}
                  </ul>
               </div>}
         </section>
      </CheckListWrap>
   )
}

export { Distinction, Overview, CertificationInfo, Benefits, Benefits02, Consulting, ContactBanner, CheckList }